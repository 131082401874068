import { Navigate } from "react-router-dom";
import { createContext } from "react";
import Loading from "../loader/Loading";

const PropertyContext = createContext();

const InsideArticle = ({ status, children }) => {

    return (<PropertyContext.Provider value={{ status }}>
        <article>{!status.ready
            ? <Loading />
            : !status.ok
                ? <Navigate to="/unauthorized" replace={true} />
                : <>{children}</>
        }</article>
    </PropertyContext.Provider>)

}


export default InsideArticle;