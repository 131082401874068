import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCoffee, faFan, faUtensils, faShirt, faSquareParking, faWifi, faKey, faPerson, faPaw, faBaby } from "@fortawesome/free-solid-svg-icons"
import { faSnowflake, faBell } from "@fortawesome/free-regular-svg-icons"
import { faAirbnb } from "@fortawesome/free-brands-svg-icons"
import { format } from 'date-fns'

const Properties = () => {

    const [properties, setProperties] = useState()
    // const axiosPrivate = useAxiosPrivate()
    // const { auth } = useAuth()

    useEffect(() => {
        // let isMounted = false
        // const controller = new AbortController()
        
        // console.log("pouet", sessionStorage.getItem("name"))

        const getProperties = async () => {
            try {
                const response = await axios().get('/property?withOwner=1&withCoffee=1&withEntries=1' /*, {
                    signal: controller.signal
                }*/)
                setProperties(response.data)
            } catch (err) {
                console.log(err)
            }
        }

        getProperties()

        /*return () => {
            isMounted = false
            controller.abort()
        }*/
    },[])

    return (
        <article>
            <h1>Propriétés</h1>
            {properties?.length
                ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Contrat</th>
                                <th>Emplacement</th>
                                <th>Surface</th>
                                <th>Nb Voyageurs</th>
                                <th>Accès WiFi</th>
                                <th>Equipements</th>
                                <th>Liens</th>
                            </tr>
                        </thead>
                        <tbody>
                            {properties.map((prop, i) =>
                                <tr key={i}>
                                    <td><Link to={`/p/${prop?.id}`}>{prop?.name}</Link></td>
                                    <td>{prop?.contract_id ? prop?.contract_id + " - " : ""}{prop?.owner?.firstname} {prop?.owner?.name}<br /><em>{ prop?.contract_start ? format(new Date(prop?.contract_start), 'dd/MM/yyyy') : ""}{ prop?.contract_end ? " - " + format(new Date(prop?.contract_end), 'dd/MM/yyyy') : ""}</em></td>
                                    <td>{prop?.add_comp}<br />{prop?.address}<br />{prop?.cp} {prop?.city}</td>
                                    <td style={{textAlign: "center"}}>{prop?.surface} m²</td>
                                    <td style={{textAlign: "center"}}>{prop?.max_travelers} <FontAwesomeIcon icon={faPerson} /></td>
                                    <td>{prop?.wifi_ssid ? (<><FontAwesomeIcon icon={faWifi} />&nbsp;{prop?.wifi_ssid}<br /><FontAwesomeIcon icon={faKey} color="darkkhaki"/>&nbsp;{prop?.wifi_key}</>) : ""}</td>
                                    <td><div>
                                        {prop?.clim ? (<><FontAwesomeIcon icon={faSnowflake} title='Climatisation' color='blue' size="xl"/>&nbsp;</>) : ""}
                                        {prop?.ventilateur ? (<><FontAwesomeIcon icon={faFan} title='Ventilateur' color="#88f" size="xl" />&nbsp;</>) : ""}
                                        {prop?.washing_machine ? (<><FontAwesomeIcon icon={faShirt} title="Lave-linge" size="xl"/>&nbsp;</>) : ""}
                                        {prop?.dishwasher ? (<><FontAwesomeIcon icon={faUtensils} title="Lave-vaisselle" size="xl"/>&nbsp;</>) : ""}
                                        {prop?.parking ? (<><FontAwesomeIcon icon={faSquareParking} title='Parking' color='blue'  size="xl"/>&nbsp;</>) : ""}
                                        {prop?.animals_allowed ? (<><FontAwesomeIcon icon={faPaw} title='Animaux autorisés' size="xl"/>&nbsp;</>) : ""}
                                        {prop?.lit_parapluie ? (<><FontAwesomeIcon icon={faBaby} title='Lit parapluie' size="xl"/>&nbsp;</>) : ""}</div>
                                        <div>{prop?.coffee ? (<><FontAwesomeIcon icon={faCoffee} />&nbsp;{prop?.coffee.label}</>) : ""}</div>
                                    </td>
                                    <td>{prop?.airbnb_id ? (
                                        <>
                                            <a href={`https://www.airbnb.fr/rooms/${prop?.airbnb_id}`} target="_new"><FontAwesomeIcon icon={faAirbnb} color='#FF5A5F' />&nbsp;Annonce</a><br />
                                            <a href={`https://www.airbnb.fr/multicalendar/${prop?.airbnb_id}`} target="_new"><FontAwesomeIcon icon={faAirbnb} color='#FF5A5F' />&nbsp;Calendrier</a><br />
                                            <a href={`https://www.airbnb.fr/hosting/listings/${prop?.airbnb_id}/details`} target="_new"><FontAwesomeIcon icon={faAirbnb} color='#FF5A5F' />&nbsp;Editer fiche</a>
                                        </>
                                    ) : ""}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )
                : (
                    <p>Rien...</p>
                )}
        </article>
    )
}

export default Properties