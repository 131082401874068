import SubNavbarLayout from "../components/subnavbarlayout/SubNavbarLayout";

function CheckinLayout() {

    const tabs = [
        { path: '/checkin', label: 'Entrées\u00A0prochaines' },
        { path: '/checkin/in-progress', label: 'Voyage\u00A0en\u00A0cours' },
        { path: '/checkin/today', label: 'Sortie\u00A0aujourd\'hui' },
        { path: '/checkin/tbu', label: 'A valider' },
        { path: '/checkin/search', label: 'Recherche' }
    ]

    return <SubNavbarLayout tabs={tabs} title="Réservations"/>
}

export default CheckinLayout;