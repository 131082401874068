import { format } from "date-fns"

export default function buildDisplayDate(dt) {
    const date = new Date(dt)
    date.setHours(0,0,0,0)
    const nowDate = new Date()
    nowDate.setHours(0,0,0,0)
    const tomorrowDate = new Date(nowDate)
    tomorrowDate.setDate(tomorrowDate.getDate() + 1)

    if (date.toDateString() == nowDate.toDateString()) {
        return "Aujourd'hui"
    } else if (date.toDateString() == tomorrowDate.toDateString()) {
        return "Demain"
    } else {
        let fDate = format(date, 'EEE. dd/MM')
        fDate = fDate.replace("Mon","Lun").replace("Tue","Mar").replace("Wed","Mer").replace("Thu","Jeu").replace("Fri","Ven").replace("Sat","Sam").replace("Sun","Dim")
        return fDate
    }
}