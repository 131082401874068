import { useParams } from "react-router-dom"
import SubNavbarLayout from "../components/subnavbarlayout/SubNavbarLayout";


function PropertyLayout() {

    const params = useParams() || {} ;
    const id = params.id || undefined;

    const tabs = [
        { path: `/p/${id}`, label: 'Paramètres' },
        { path: `/p/${id}/edit`, label: 'Edition' },
        { path: `/p/${id}/entries`, label: 'Entrées' },
        { path: `/p/${id}/planning`, label: 'Planning' }
    ]

    return <SubNavbarLayout tabs={tabs} />
}

export default PropertyLayout;