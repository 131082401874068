import React from "react";
import "./styles/App.sass"
import Home from "./pages/Home";
import HouseClean from "./pages/HouseClean";
import HouseCleanBis from "./pages/HouseCleanBis";
import CheckinNext from "./pages/CheckinNext";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Reservation from "./pages/Reservation";
import PropertyLayout from "./pages/PropertyLayout";
import PropertyDisplay from "./pages/PropertyDisplay";
import PropertyEditor from "./pages/PropertyEditor";
import PropertyEntries from "./pages/PropertyEntries";
import PropertyPlanning from "./pages/PropertyPlanning";
import Properties from "./pages/Properties";
import Clients from "./pages/Clients";
import RequiredAuth from "./pages/RequiredAuth";
import { Routes, Route } from "react-router-dom";
import Unauthorized from "./pages/Unauthorized";
import CheckinLayout from "./pages/CheckinLayout";
import CheckinInProgress from "./pages/CheckinInProgress";
import CheckinToday from "./pages/CheckinToday";
import CheckinSearch from "./pages/CheckinSearch";
import CheckinToBeValidated from "./pages/CheckinToBeValidated";
import PrivateLayout from "./pages/PrivateLayout";
import PrivateTodo from "./pages/PrivateTodo";
import PrivateHcPoll from "./pages/PrivateHcPoll";
import HousecleanBags from "./pages/HousecleanBags";
import PrivateDevices from "./pages/PrivateDevices";
import PrivateSMS from "./pages/PrivateSMS";
import HousecleanInvoices from "./pages/HousecleanInvoices";
import PrivateCsvDeposit from "./pages/PrivateCsvDeposit";
import Client from "./pages/Client";
import ClientEditor from "./pages/ClientEditor";
import HouseKeeper from "./pages/HouseKeeper";
import HouseKeeperSynthesis from "./pages/HouseKeeperSynthesis";
import HouseKeeperLayout from "./pages/HouseKeeperLayout";
import PrivateEatventure from "./pages/PrivateEatventure";
import HouseCleanLayout from "./pages/HouseCleanLayout";

const ROLES = {
  Checkin: 1,
  HouseClean: 2,
  HouseKeeper: 3
}

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public */}
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* privée */}
        <Route element={<RequiredAuth allowedRoles={[ROLES.Checkin]}/>}>
          <Route path="checkin" element={<CheckinLayout />}>
            <Route index  element={<CheckinNext />} />
            <Route path="in-progress"  element={<CheckinInProgress />} />
            <Route path="today"  element={<CheckinToday />} />
            <Route path="tbu"  element={<CheckinToBeValidated />} />
            <Route path="search"  element={<CheckinSearch />} />
          </Route>
          <Route path="properties" element={<Properties />} />
          <Route path="r/:id" element={<Reservation />} />
          <Route path="p/:id" element={<PropertyLayout />} >
            <Route index element={<PropertyDisplay />} />
            <Route path="edit" element={<PropertyEditor />} />
            <Route path="entries" element={<PropertyEntries />} />
            <Route path="planning" element={<PropertyPlanning />} />
          </Route>
        </Route>

        <Route element={<RequiredAuth allowedRoles={[ROLES.HouseClean]}/>}>
          <Route path="houseclean" element={<HouseCleanLayout />}>
            <Route index  element={<HouseClean />} />
            <Route path="check" element={<HouseCleanBis />} />
            <Route path="bags" element={<HousecleanBags />} />
            <Route path="invoices" element={<HousecleanInvoices />} />
          </Route>
          <Route path="clients" element={<Clients />} />
          <Route path="c/:id">
            <Route index  element={<Client />} />
            <Route path="edit" element={<ClientEditor />} />
          </Route>
          <Route path="private" element={<PrivateLayout />}>
            <Route index element={<PrivateTodo />} />
            <Route path="hc-poll" element={<PrivateHcPoll />} />
            <Route path="devices" element={<PrivateDevices />} />
            <Route path="sms" element={<PrivateSMS />} />

            <Route path="eatventure" element={<PrivateEatventure />} />
            <Route path="csv-deposit" element={<PrivateCsvDeposit />} />
          </Route>
        </Route>

        <Route element={<RequiredAuth allowedRoles={[ROLES.HouseKeeper]}/>}>
          <Route path="hk" element={<HouseKeeperLayout />} >
            <Route index element={<HouseKeeper />} />
            <Route path="synthesis" element={<HouseKeeperSynthesis />} />
          </Route>/
        </Route>

        {/* catch all */}
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}

/*

      <Route path="/" element={<Layout />}>
      </Route>


      */
