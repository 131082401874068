import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

const Clients = () => {

    const [clients, setClients] = useState()

    useEffect(() => {

        const getClients = async () => {
            try {
                const response = await axios().get('/client')
                setClients(response.data)
            } catch (err) {
                console.log(err)
            }
        }

        getClients()

    },[])

    return (
        <article>
            <h1>Clients</h1>
            {clients?.length
                ? (
                    <table>
                        <thead>
                            <th>Nom</th>
                            <th>Adresse</th>
                            <th>Etat civil</th>
                            <th>Contact</th>
                        </thead>
                        <tbody>
                            {clients.map((client, i) =>
                                <tr key={i}>
                                    <td><Link to={`/c/${client?.id}`}>{client?.gender} {client?.firstname} {client?.name}</Link></td>
                                    <td>{client?.address}<br />{client?.cp} {client?.city}</td>
                                    <td>
                                        {client?.birth_date ? "le " + format(new Date(client?.birth_date), 'dd/MM/yyyy') : ""}<br />
                                        {client?.birth_place ? "à " + client?.birth_place : ""} <br />
                                        {client?.nationality ? "nationalité " + client?.nationality : ""}</td>
                                    <td>{client?.phone}<br />{client?.email}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )
                : (
                    <p>Rien...</p>
                )}
        </article>
    )
}

export default Clients