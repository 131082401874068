import { useLocation, Navigate, Outlet } from "react-router-dom";
// import useAuth from "../hooks/useAuth";
import { authController } from "../controllers/authController";

const RequiredAuth  = ( { allowedRoles }) => {
    //const {auth} = useAuth()
    const location = useLocation()

    // console.log(authController.getRoles())

    return (
        authController.getRoles()?.find( role => allowedRoles?.includes(role))
        ? <Outlet />
        : authController.isLogged()
            ? <Navigate to="/unauthorized" state={{from: location}} replace />
            : <Navigate to="/login" state={{from: location}} replace />
    )
}


export default RequiredAuth