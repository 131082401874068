import { Link, useParams } from "react-router-dom";
import axios from "../api/axios"
import { useState, useEffect } from "react";
// import { format } from "date-fns";
// import buildDisplayDate from "../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { faAirbnb } from "@fortawesome/free-brands-svg-icons";
import { BallTriangle } from "react-loader-spinner"
import { Navigate } from 'react-router-dom';

const Loading = () => <BallTriangle
    height={100}
    width={100}
    radius={5}
    color="#4fa94d"
    ariaLabel="ball-triangle-loading"
    wrapperClass={{}}
    wrapperStyle=""
    visible={true}
/>

function PropertyDisplay() {

    const [property, setProperty] = useState({ok:false, loading:true})
    const params = useParams() || {} ;
    const id = params.id || undefined;

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios().get(`/property/${id}?withOwner=1&withCoffee=1&withICalURL=1`)
                const p = {  ...response.data, ok:true, loading:false }
                setProperty(p)
            } catch (error) {
                if (error.response) {
                    let { status, statusText } = error.response;
                    if (status == 404) {
                        setProperty({ok:false, loading:false})
                    } else {
                        setProperty({ok:false, loading:true})
                        console.log(status, statusText);
                    }
                } else {
                    setProperty({ok:false, loading:true})
                    // console.log(error);
                }
            }
        }
        fetchData()
    }, []);

    return property.loading
        ? <Loading />
        : !property.ok
            ? <Navigate to="/unauthorized" replace={true}/>
            : <>
                <h1>Logement {property.name}</h1>
                <ul>
                    <li>Propriétaire : {property.owner?.name} {property.owner?.firstname} (<Link to={"/c/" + property.owner?.id}><FontAwesomeIcon icon={faUpRightFromSquare} size="xs" /></Link>)</li>
                    <li>Adresse : {property.address} - {property.cp} {property.city}</li>
                    <li>Description : {property.description}</li>
                    <li>Commission : {property.commission} %</li>
                    <li>Frais de ménage : {property.houseclean} €</li>
                    <li>Lave-vaisselle : {property.dishwasher ? "Oui" : "Non"}</li>
                    <li>Lave-linge : {property.washing_machine ? "Oui" : "Non"}</li>
                    <li>iCal : TBD</li>
                    <li>Nombre de voyageurs : {property.max_travelers}</li>
                    <li>Wifi SSID : {property.wifi_ssid}</li>
                    <li>Wifi Clé : {property.wifi_key}</li>
                    <li>Airbnb ID : {property.airbnb_id}</li>
                    <li><a href={`https://www.airbnb.fr/rooms/${property?.airbnb_id}`} target="_new"><FontAwesomeIcon icon={faAirbnb} color='#FF5A5F' />&nbsp;Annonce</a></li>
                    <li><a href={`https://www.airbnb.fr/multicalendar/${property?.airbnb_id}`} target="_new"><FontAwesomeIcon icon={faAirbnb} color='#FF5A5F' />&nbsp;Calendrier</a></li>
                    <li><a href={`https://www.airbnb.fr/hosting/listings/${property?.airbnb_id}/details`} target="_new"><FontAwesomeIcon icon={faAirbnb} color='#FF5A5F' />&nbsp;Editer fiche</a></li>
                </ul>
            </>
    

}

export default PropertyDisplay;
