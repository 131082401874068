import logo from "./LocEM_web.png"
import "./Navbar.sass"
import { Link } from "react-router-dom";
import { useState } from "react"
import { faBroom, faLock, faSignOutAlt, faUsers, faBuilding, faTasks, faHardHat  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

function Navbar() {

  const [showLinks,setShowLinks] = useState(false)

  const tabs = [
    { path: '/checkin', label: 'Réservations', icon: faCalendar },
    { path: '/houseclean', label: 'Ménage', icon: faTasks },
    { path: '/properties', label: 'Logements', icon: faBuilding },
    { path: '/clients', label: 'Clients', icon: faUsers },
    { path: '/hk', label: 'Mes ménages', icon: faBroom },
    { path: '/private', label: 'Privé', icon: faLock },
    { path: '/logout', label: 'Se déconnecter', icon: faSignOutAlt },
  ]

  const toggleShowLinks = () => {
    setShowLinks(!showLinks)
  }

  const handleHideLinks = (event) => {
    // event.preventDeafult()
    setShowLinks(false)
  }

  return (<nav className={`navbar ${showLinks ? "show-nav" : "hide-nav"}`}>
      <div className="nav-logo"><img src={logo} style={{verticalAlign: 'text-top'}}/></div>
      <ul className="nav-links">
      {tabs.map((tab) => (
                        <li key={tab.path}>
                            <Link
                                to={tab.path}
                                onClick={handleHideLinks}
                                className={tab.path === location.pathname ? "active" : ""}><FontAwesomeIcon icon={tab.icon} color="SeaGreen" /><span className="nav-label">{tab.label}</span></Link>
                        </li>
                    ))}
      </ul>
      <button className="nav-burger" onClick={toggleShowLinks}><span className="burger-line"></span></button>
    </nav>
  );
}

export default Navbar;