import { faAirbnb } from "@fortawesome/free-brands-svg-icons"
import { faBold, faPerson } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./BrokerDisplay.sass"

const BrokerDisplay = ({ reservation, property }) => {
    return <span className="broker">{
        reservation.is_airbnb
            ? <a href={`https://www.airbnb.fr/hosting/reservations/details/${reservation.airbnb_code}`} target={`_details_${reservation.airbnb_code}`}><FontAwesomeIcon icon={faAirbnb} color="#FF5A5F" /></a>
            : ""
    }
    {
        reservation.is_booking
        ? (reservation.booking_id 
            ? <a href={`https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/booking.html?res_id=${reservation.booking_id}&hotel_id=${property ? property.booking_id : reservation.property.booking_id}&lang=fr`} target={`_details_${reservation.booking_code}`}><FontAwesomeIcon icon={faBold} style={{ color: '#003b95' }} /></a> 
            : <a href={`https://admin.booking.com/`}><FontAwesomeIcon icon={faBold} style={{ color: 'grey' }} /></a>
            ) 
        : ""
    }
    {
        reservation.for_owner ?
        <FontAwesomeIcon icon={faPerson} />
        : ""
    }</span>
}

export default BrokerDisplay