import { useState, useEffect, createContext } from "react"
import Loading from "../components/loader/Loading";
import axios from "../api/axios";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faArrowCircleLeft, faArrowLeft, faBagShopping, faCaretRight, faCaretSquareRight, faCheck, faRedo, faShoppingBag, faSync, faSyncAlt, faUndo, faUndoAlt } from "@fortawesome/free-solid-svg-icons"

const PropertyContext = createContext();

const Inside = ({ status, children }) => {

    return (<PropertyContext.Provider value={{ status }}>
        <article>{status.loading
            ? <Loading />
            : !status.ok
                ? <h1>Non</h1>
                : <>{children}</>
        }</article>
    </PropertyContext.Provider>)

}

const GenericTable = ({data}) => {

    if (data.length == 0) {
        return <div>Table vide</div>
    }
    const keys = Object.keys(data[0])

    return <table>
        <thead>
            <tr key="th">
                {keys.map((item, idx) => <th>{item}</th>)}
            </tr>
        </thead>
        <tbody>
            
            {data.map((item, idx) =>
            (<tr key={idx}>
                {keys.map(key => {
                    if (Object.prototype.toString.call(item[key]) === '[object Object]') {
                        const subtableData = Object.entries(item[key])
                        return <GenericTable data={subtableData} />
                    } else {
                        return <td>{item[key]}</td>
                    }
                })}
            </tr>))}
        </tbody>
    </table>
}

const Table = ({data}) => {

    const [content, setContent] = useState(data)

    if (content.length == 0) {
        return <div>Table vide</div>
    }

    const keys = ["Date", "Logement", "Aide-Ménagère", " "]
    const thead = <thead><tr key="th">{keys.map((item, idx) => <th key={idx}>{item}</th>)}</tr></thead>

    const getTrId = (event) => {
        const closestTr = event.target.closest("tr")
        if (!closestTr)
            alert("Heing ?")
        const id = closestTr.getAttribute("data-key")
        return id
    }

    const handleRedoClick = async (event) => {
        const id = getTrId(event)
        if (false || confirm(`On déplace le ménage de ${content[id].housekeeper.name} à ${content[id].property.name} à aujourd'hui ?`)) {
            try {
                const result = await axios().put("/reservation/hc-now/" + content[id].id)
                const copiedContent = [ ...content]
                copiedContent.splice(id,  1)
                setContent(copiedContent)
            } catch (error) {
                alert("Erreur de l'appel")
            }
        }
    }

    const handleDoneClick = async (event) => {
        const id = getTrId(event)
        if (false || confirm(`On valide le ménage de ${content[id].housekeeper.name} ?`)) {
            try {
                const reservationToUpdate = {id: content[id].id, hc_done: 1}
                console.log(reservationToUpdate)
                const result = await axios().post("/reservation/1", reservationToUpdate)
                const copiedContent = [ ...content]
                copiedContent.splice(id,  1)
                setContent(copiedContent)
            } catch (error) {
                alert("Erreur de l'appel")
            }
        }
    }

    return <table>
        { thead }
        <tbody>
            {content.map((item, idx) =>
            (<tr key={idx} data-key={idx}>
                <td>{ format(new Date(item.dt_to), 'eee. dd/MM') } <FontAwesomeIcon icon={faRedo} color="Black" onClick={handleRedoClick}/> <FontAwesomeIcon icon={faCheck} color="Black" onClick={handleDoneClick}/></td>
                <td>{item.property.name}</td>
                <td>{item.housekeeper.name}</td>
                <td><FontAwesomeIcon icon={faBagShopping} color={ item.hc_sac_livre ? "SeaGreen" : "Crimson" } /></td>
            </tr>))}
        </tbody>
    </table>
}

function HouseCleanBis() {

    const [pageStatus, setPageStatus] = useState({loading:true, ok:false})
    const [reservations, setReservations] = useState([])

    const fetchData = async () => {
        try {
            const result = await axios().get("/reservation/prev-hc")
            if (result.status == 200) {
                setReservations(result.data)
                setPageStatus({ loading: false, ok: true })
            } else {
                setPageStatus({ loading: false, ok: false })
            }
        } catch (error) {
            setPageStatus({ loading: false, ok: false })
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return <Inside status={pageStatus}>
        <Table data={reservations}/>
    </Inside>
}

export default HouseCleanBis;