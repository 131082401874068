const { app_url: APP_URL } = require("../../config.json")

export const getLink = (reservation) => {
    let link = "#"
    if (reservation.is_airbnb) {
        link = `${APP_URL}r/${reservation.airbnb_code}`
    } else if (reservation.is_booking) {
        link = `${APP_URL}b/${reservation.booking_id}`
    }
    return link
}

const AppLink = ({ reservation, children }) => {

    const link = getLink(reservation)
    let target = "_new"
    if (reservation.is_airbnb) {
        target = `app_r_${reservation.airbnb_code}`
    } else if (reservation.is_booking) {
        target = `app_b_${reservation.airbooking_idbnb_code}`
    }
    return <a target={target} href={link}>
        {children}
    </a>
}

export default AppLink