import SubNavbarLayout from "../components/subnavbarlayout/SubNavbarLayout";

function PrivateLayout() {

    const tabs = [
        { path: '/private', label: 'Todo quotidienne' },
        { path: '/private/hc-poll', label: 'HC Poll' },
        { path: '/private/devices', label: 'Etat Nuki' },
        { path: '/private/sms', label: 'SMS' },
        { path: '/private/csv-deposit', label: 'Déposer le CSV' }
    ]

    return <SubNavbarLayout tabs={tabs} title="Privé" />
}

export default PrivateLayout;