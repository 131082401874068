import SubNavbarLayout from "../components/subnavbarlayout/SubNavbarLayout";


function HouseCleanLayout() {

    const tabs = [
        { path: '/houseclean', label: 'Prochains ménages' },
        { path: '/houseclean/check', label: 'Vérification' },
        { path: '/houseclean/bags', label: 'Planning sacs' },
        { path: '/houseclean/invoices', label: 'Factures' }
    ]

    return <SubNavbarLayout tabs={tabs} title="Ménages" />

}

export default HouseCleanLayout;