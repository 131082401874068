import { useParams, useNavigate, Navigate } from "react-router-dom";
import { getClient, updateClient } from "../utils/RestLocem";
import { useState, useEffect } from "react";
import EditPanel from "../components/panel/EditPanel";

function ClientEditor() {

    const [client, setClient] = useState({})
    const params = useParams() || {} ;
    const id = params.id || undefined;
    const navigate = useNavigate()

    const fieldsMap = [
        {field:"gender", label:"M/Mme", placeholder:"M/Mme"},
        {field:"firstname", label:"Prénom"},
        {field:"name", label:"Nom"},
        {field:"address", label:"Adresse"},
        {field:"cp", label:"Code Postal"},
        {field:"city", label:"Ville"},
        {field:"birth_date", label:"Date de naissance", type:"date" },
        {field:"birth_place", label:"Lieu de naissance" },
        {field:"nationality", label:"Nationalité", placeholder:'française' },
        {field:"phone", label:"Téléphone" },
        {field:"email", label:"EMail" }
    ]

    const fetchData = async () => {
        try {
            const data = await getClient(id)
            const p = {  ...data, ok:true, loading:false }
            setClient(p)
            console.log(p)
        } catch (error) {
            if (error.response) {
                let { status, statusText } = error.response;
                if (status == 404) {
                    setClient({ok:false, loading:false})
                } else {
                    setClient({ok:false, loading:true})
                    console.log(status, statusText);
                }
            } else {
                setClient({ok:false, loading:true})
                // console.log(error);
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    const handleSave = async (data) => {
        console.log(data)
        try {
            await updateClient({ ...data, id:client.id})
            navigate("..", { replace: true })
        } catch (e) {
            alert("Problème dans l'envoi")
        }
    }

    return client.loading
        ? <Loading />
        : !client.ok
            // ? <Navigate to="/unauthorized" replace={true}/>
            ? <>Pourqui</>
            : <>
                <h2>Edition Client</h2>
                <EditPanel fields={fieldsMap} data={client} onSave={handleSave}/>
            </>


}

export default ClientEditor;
