import "./Popup.sass"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function Popup(props) {

    
    return (props.trigger) ? (
        <div className="popup">
        <div className="popup-inner">
            <button className="close-btn" onClick={() => props.setTrigger(false)}>
                <FontAwesomeIcon icon={faTimes} className="close-icon" />
            </button>
            { props.children }
        </div>
    </div>
    ) : "";
    
}

export default Popup;