import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faFullStar} from "@fortawesome/free-solid-svg-icons"
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons"
import { format } from 'date-fns'

const PrivateTodo = () => {

    const [hcPoll, setHcPoll] = useState()


    useEffect(() => {


        const loadHcPoll = async () => {
            try {
                const response = await axios().get('/private/hc-poll')
                setHcPoll(response.data)
            } catch (err) {
                console.log(err)
            }
        }

        loadHcPoll()

    },[])

    return (
        <article>
            {hcPoll?.length
                ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Logement</th>
                                <th>Voyageur</th>
                                <th colSpan="2">Date</th>
                                <th>Aide ménagère</th>
                                <th>Note</th>
                                <th>Commentaires</th>
                                <th>-</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hcPoll.map((item, i) =>
                                <tr key={i}>
                                    <td>{item?.p_name}</td>
                                    <td>{item?.traveler}</td>
                                    <td>{format(new Date(item?.dt_from), 'dd/MM')}</td>
                                    <td>{format(new Date(item?.dt_to), 'dd/MM')}</td>
                                    <td>{item?.h_name}</td>
                                    <td>
                                        {[...Array(item.note)].map((e, i) => <FontAwesomeIcon icon={faFullStar} size='sm' color='DarkKhaki'/>)}
                                        {[...Array(5 - item.note)].map((e, i) => <FontAwesomeIcon icon={faEmptyStar} size='sm'  color='DarkKhaki'/>)}
                                    </td>
                                    <td>{item?.comms}</td>
                                    <td>{item?.clean_items}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )
                : (
                    <p>Rien...</p>
                )}
        </article>
    )
}

export default PrivateTodo