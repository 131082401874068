import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDoorClosed, faBatteryQuarter } from "@fortawesome/free-solid-svg-icons"
import { faBell } from "@fortawesome/free-regular-svg-icons"
import { format } from 'date-fns'
import "./PrivateDevices.sass"

const PrivateTodo = () => {

    const [devices, setDevices] = useState()


    useEffect(() => {


        const loadDevices = async () => {
            try {
                const response = await axios().get('/private/devices')
                console.log(response.data)
                setDevices(response.data)
            } catch (err) {
                console.log(err)
            }
        }

        loadDevices()

    },[])

    return (
        <article>
            {devices?.length
                ? (
                    <table id="devices-table">
                        <thead>
                            <tr>
                                <th>-</th>
                                <th>Device</th>
                                <th>Date</th>
                                <th colSpan={2}>Batterie</th>
                                <th>Keypad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map((item, i) => {
                                let cname = "bat-low"
                                if (item?.battery_charge > 72) {
                                    cname = "bat-medium"
                                }
                                if (item?.battery_charge >= 80) {
                                    cname = "bat-high"
                                }
                                return (
                                    <tr key={i}>
                                        <td className="not-media"><FontAwesomeIcon icon={faCircle} color={item?.online === 1 ? 'SeaGreen' : 'Crimson'} title={item?.online === 1 ? 'Online' : 'Offline'} /></td>
                                        <td className="not-media">{item?.type === 1 ? (<FontAwesomeIcon icon={faDoorClosed} title="SmartLock" />) : (<FontAwesomeIcon icon={faBell} title="Opener" />)} {item?.__p_name}</td>
                                        <td className="not-screen">{item?.type === 1 ? (<FontAwesomeIcon icon={faDoorClosed} title="SmartLock" color={item?.online === 1 ? 'SeaGreen' : 'Crimson'}/>) : (<FontAwesomeIcon icon={faBell} title="Opener" color={item?.online === 1 ? 'SeaGreen' : 'Crimson'} />)} {item?.__p_name}</td>
                                        <td className="not-media">{format(new Date(item?.online_lasttime), 'dd/MM HH:mm')}</td>
                                        <td className="not-screen">{format(new Date(item?.online_lasttime), 'HH:mm')}</td>
                                        <td><span className={cname}>{item?.battery_charge}%</span></td>
                                        <td>{item?.battery_critical === 0 ? '' : <FontAwesomeIcon icon={faBatteryQuarter} title="Opener" color="Crimson" />}</td>
                                        <td>{item?.keypad_battery_critical === 0 ? '' : <FontAwesomeIcon icon={faBatteryQuarter} title="Opener" color="Crimson" />}</td>
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                )
                : (
                    <p>Rien...</p>
                )}
        </article>
    )
}

export default PrivateTodo