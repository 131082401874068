import React, { useState } from 'react';
import axios, { axiosUpload } from "../api/axios"

function PrivateCsvDeposit() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadFile = async () => {
    if (!selectedFile) {
      console.error('No file selected');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const result = await axios().put('transaction', formData);
      console.log('File uploaded successfully');
      // Gérer la réponse, si nécessaire
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={uploadFile}>Upload File</button>
    </div>
  );
}

export default PrivateCsvDeposit;