import React from "react";
import axios from "../../api/axios"
import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBroom, faHouse, faFilePen, faShoppingBag, faSyncAlt, faCoffee, faUtensils, faShirt } from "@fortawesome/free-solid-svg-icons"
import "./HouseKeeper.sass"
import PropertyPopup from "./PropertyPopup";
import { differenceInDays } from 'date-fns'
import InsideArticle from "../article/InsideArticle";

function HouseKeeper() {

    const [status, setStatus] = useState({ ready: false, ok: false })

    const [housecleanList, setHousecleanList] = useState()
    const [popupDisplay, setPopupDisplay] = useState(false)
    const [popupReservation, setPopupReservation] = useState()
    const [smartlockCode, setSmartlockCode] = useState()
    const [properties, setProperties] = useState()

    useEffect(() => {


        const buildHKList = async () => {
            try {
                const receivedHousecleanList = await axios().get('/hk?withEntries=1&withCoffee=1')
                setHousecleanList(receivedHousecleanList.data)
                // TODO : Récupérer le smartlock_code depuis la table housekeeper et non user !
                const me = await axios().get('/user/me')
                setSmartlockCode(me.data.smartlock_code)
                const properties = await axios().get('/housekeeper/properties')
                setProperties(properties.data)
                setStatus({ ready: true, ok: true })
            } catch (err) {
                console.log(err)
            }
        }

        buildHKList()

    },[])

    const handleEditClick = (event, i) =>{
        setPopupReservation(housecleanList[i])
        setPopupDisplay(true)
    }

    return (
        <InsideArticle status={status}>
        <article>
            {housecleanList?.length
                ? (
                    <table className="hk-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th className="not-screen">Date</th>
                                <th>Nuits</th>
                                <th>Simple ménage</th>
                                <th>Reloué</th>
                                <th>Sac dispo</th>
                                <th className="not-screen"> </th>
                                <th>Plage</th>
                                <th>Logement</th>
                                <th>Consommables</th>
                                <th>Rapport</th>
                            </tr>
                        </thead>
                        <tbody>
                            {housecleanList.map((item, i) => {
                                const toDate = new Date(item.dt_to)
                                toDate.setHours(0, 0, 0, 0)
                                const nowDate = new Date()
                                nowDate.setHours(0, 0, 0, 0)
                                const tomorrowDate = new Date(nowDate)
                                tomorrowDate.setDate(tomorrowDate.getDate() + 1)

                                let win = `${item.wished_checkout} - 17:00`
                                let oneDay = false
                                const livre = item['hc_sac_livre'] == 1
                                // console.log(item['id'], livre)
                                if (item.next != undefined) {
                                    const nextToDate = new Date(item.next.dt_from)
                                    nextToDate.setHours(0,0,0,0)
                                    oneDay = toDate.toDateString() == nextToDate.toDateString()
                                    if (oneDay) {
                                        win = `${item.wished_checkout} - ${item.next.wished_checkin}`
                                     }
                                } 

                                let dt_to = ""
                                let className = "notToday"
                                let today = false

                                const nuit = differenceInDays(new Date(item.dt_to), new Date(item.dt_from))
                            
                                if (toDate.toDateString() == nowDate.toDateString()) {
                                    dt_to = "Aujourd'hui"
                                    className = "today"
                                    today = true
                                } else if (toDate.toDateString() == tomorrowDate.toDateString()) {
                                    dt_to = "Demain"
                                } else {
                                    dt_to = format(toDate, 'EEE. dd/MM')
                                    dt_to = dt_to.replace("Mon","Lun").replace("Tue","Mar").replace("Wed","Mer").replace("Thu","Jeu").replace("Fri","Ven").replace("Sat","Sam").replace("Sun","Dim")
                                }
                                
                                return (
                                    <tr key={i} className={className}>
                                        <td data-label="Date" className="not-media">{dt_to}</td>
                                        <td data-label="Date" className="not-screen">{dt_to} {win}</td>
                                        <td data-label="Nombre nuits">{nuit ? nuit : "-"}</td>
                                        <td data-label="Simple ménage" className="not-media">{ item?.is_houseclean ? (<><FontAwesomeIcon icon={faBroom} title='Oui' color='green' size="lg"/> Oui</>) : ""}</td>
                                        <td data-label="Reloué" className="not-media">{oneDay ? (<><FontAwesomeIcon icon={faSyncAlt} title='Oui' color='green' size="lg"/> Oui</>) : ""}</td>
                                        <td data-label="Sac dispo" className="not-media">{livre ? (<><FontAwesomeIcon icon={faShoppingBag} title='Oui' color='green' size="lg"/> Oui</>) : ""}</td>
                                        <td className="not-screen">
                                            {item?.is_houseclean ? (<> <FontAwesomeIcon icon={faBroom} title='simple ménage' color='green' size="lg"/> Simple ménage </>) : "\u00A0"}
                                            {oneDay ? (<> <FontAwesomeIcon icon={faSyncAlt} title='reloué' color='green' size="lg"/> Reloué </>) : "\u00A0"}
                                            {livre ? (<> <FontAwesomeIcon icon={faShoppingBag} title='sac prêt' color='green' size="lg"/> Sac prêt </>) : "\u00A0"}
                                        </td>
                                        <td data-label="Plage" className="not-media">{win}</td>
                                        <td data-label="Logement"><span className="prop-span" onClick={(event) => handleEditClick(event, i)}><FontAwesomeIcon icon={faHouse}/> {item?.property.name}</span></td>
                                        <td data-label="Consommables">
                                            <div><FontAwesomeIcon icon={faCoffee}/> {item?.property.coffee.label}</div>
                                            {item?.property?.washing_machine ? <div><FontAwesomeIcon icon={faShirt}/> Pastille Lave-linge</div> : ""}
                                            {item?.property?.dishwasher ? <div><FontAwesomeIcon icon={faUtensils}/> Pastille Lave-vaisselle</div> : ""}
                                        </td>
                                        <td data-label="Rapport" className={today ? "" : "not-media"}>{!today ? "\u000A" : <a target={`_fill${item.id}_`} href={`https://forms.fillout.com/t/3THorEGZbius?resa=${item.id}&pname=${item.property.name}`}><FontAwesomeIcon icon={faFilePen} title='Rédiger' color='grey' size="lg"/> Rapport</a>}</td>
                                    </tr>
                                )}
                            )}
                        </tbody>
                    </table>
                )
                : (
                    <div>Aucun ménage à venir</div>
                )}
            <PropertyPopup
                trigger={popupDisplay}
                setTrigger={setPopupDisplay}
                reservation={popupReservation}
                smartlockCode={smartlockCode}
                properties={properties}
            />
        </article>
        </InsideArticle>
    );
}

export default HouseKeeper;