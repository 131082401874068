import Popup from "../popup/Popup"

function SMSPopup(props) {


    return (
        <Popup trigger={props.trigger} setTrigger={props.setTrigger}>
            <form onSubmit={props.onSubmit}>
            Envoyer un SMS au <input type="text" name="phone" value={props.formData.phone} onChange={props.onChange}/>
            <br />
            <textarea name="text" id="" cols="80" rows="15" onChange={props.onChange} value={props.formData.text} />
            <br />
            <input type="submit" value="Envoyer" /><input type="button" value="Suivant" onClick={props.onNext} />
            </form>
        </Popup>
    )

}

export default SMSPopup;