import {useRef} from "react"
import Popup from "../popup/Popup"

function SMSPopup(props) {

    const textRef = useRef()

    const copyToClipboard = (event) => {
        event.preventDefault()
        navigator.clipboard.writeText(textRef.current.value)
    }

    const handleNormalText = (event) => {
        event.preventDefault()
        textRef.current.value = props.formData.text
    }

    const handleLinkText = (event) => {
        event.preventDefault()
        textRef.current.value = `Bonjour ${props.formData.name}\nBonne nouvelle, le logement est disponible, toutes les infos et accès en suivant ce lien:\n${props.formData.link}\nExcellent séjour\nEric`
    }
    
    return (
        <Popup trigger={props.trigger} setTrigger={props.setTrigger}>
            <form onSubmit={props.onSubmit}>
            <div>Envoyer un SMS au <input type="text" name="phone" value={props.formData.phone} onChange={props.onChange}/></div>
            <div><button onClick={handleNormalText}>Standard</button> <button onClick={handleLinkText}>Lien App</button></div>
            <textarea ref={textRef} name="text" id="" cols="80" rows="15" onChange={props.onChange} value={props.formData.text} />
            <div>
            <input type="submit" value="Envoyer" />
            <input type="button" value="Copier" onClick={copyToClipboard} />
            </div>
            </form>
        </Popup>
    )

}

export default SMSPopup;