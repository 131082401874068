import { useState, useEffect } from "react";
import "./EditPanel.sass"

const EditPanel = ({ fields, data, onSave }) => {

    const [storedData, setStoredData] = useState({})

    useEffect (() => {
        const editedData = {}
        fields.forEach((item, idx) => {
            editedData[[item.field]] = data[[item.field]]
            if (item.type == "date") {
                editedData[[item.field]] = new Date(data[[item.field]]).toLocaleDateString('en-CA')
            }
        })
        setStoredData(editedData)

    }, [])

    const handleChange = (event) => {
        let {name, type, value, checked} = event.target

        if (type == "checkbox") {
            value = checked ? 1 : 0
        } else {
            event.preventDefault();
        }

        const editedProperty = { ...storedData }
        editedProperty[name] = value
        setStoredData(editedProperty)
    }

    const handleSaveButtonClick = (event) =>{
        event.preventDefault();
        onSave(storedData)
    }
    
    return <div><ul>
        {fields.map((item, idx) => {
            const inputParams = {
                type:item.type || "text", 
                name:item.field, 
                id:item.field, 
                placeholder:item.placeholder,
                onChange:handleChange
            }
            if (item.type== "bool") {
                inputParams.type = "checkbox"
                inputParams.checked = storedData[[item.field]] === 1 ? true : false
            } else {
                inputParams.value = storedData[[item.field]]
            }
            var input = <input { ...inputParams} />
            return <li key={idx}><label>{item.label || item.field} :</label>{input}{item.suffix}</li>
        })}</ul><input type="button" value="Sauver" onClick={handleSaveButtonClick}/></div>
}

export default EditPanel;
