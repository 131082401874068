import { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import axios from "../api/axios"
import { format } from 'date-fns'

const Client = () => {

    const [client, setClient] = useState({})
    const params = useParams() || {} ;
    const id = params.id || undefined;

    useEffect(() => {

        const fetchClient = async () => {
            try {
                const response = await axios().get(`/client/${id}`)
                setClient(response.data)
            } catch (error) {
                if (error.response) {
                    const { status, statusText } = error.response;
                    if ( status == 404 ) {
                        setClient(undefined)
                    } else {
                        console.log(status, statusText);
                    }
                } else {
                    console.log("Perte de connexion");
                }
            }
        }

        fetchClient()

    },[])

    return (
        <article>
            {client
                ? (
                    <>
                        <h2>{client.gender} {client.firstname} {client.name}</h2>
                        <ul>
                            <li>{client.address}, {client.cp} {client.city}</li>
                            <li>Né(e) { client.birth_date ? (<>le {format(new Date(client.birth_date), "dd/MM/yyyy")},</>) : ""} à {client.birth_place}, nationalité {client.nationality}</li>
                            <li>Téléphone : {client.phone}</li>
                            <li>Mail : {client.email}</li>
                        </ul>
                        <Link to="./edit">Editer</Link>
                    </>
                )
                : (
                    <>
                        <h1>Client</h1>
                        <p>Rien...</p>
                    </>
                )}
        </article>
    )
}

export default Client