const TOKEN_KEY = "token"
const ROLES_KEY = "roles"
const NAME_KEY = "name"

const saveData = (data) => {
    localStorage.setItem(TOKEN_KEY, data?.accessToken)
    localStorage.setItem(ROLES_KEY, data?.roles.join("#"))
    localStorage.setItem(NAME_KEY, data?.name)
}

const isLogged = () => {
    return !!localStorage.getItem(TOKEN_KEY)
}

const getToken = () => {
    return localStorage.getItem(TOKEN_KEY)
}
const getName = () => {
    return localStorage.getItem(NAME_KEY)
}
const getRoles = () => {
    // const roles = sessionStorage.getItem(ROLES_KEY)
    // if (roles) {}
    // console.log(sessionStorage.getItem(ROLES_KEY)?.split("#").map(x => parseInt(x)))
    return localStorage.getItem(ROLES_KEY)?.split("#").map(x => parseInt(x))
}

const removeData = () => {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(ROLES_KEY)
    localStorage.removeItem(NAME_KEY)
}

export const authController = {saveData, isLogged, getToken, getRoles, getName, removeData}