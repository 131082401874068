import axios from "axios";
import { authController } from '../controllers/authController'
import config from "../config.json"

const API_URL = config.api_url

const getHeaders = (options = {}) => {
    const {
        noJSON=false
    } = options
    const headers = {}
    if (!noJSON) {
        headers['Content-Type'] = 'application/json'
    }
    if (authController.isLogged()) {
        headers['Authorization'] = `Bearer ${authController.getToken()}`
    }
    return headers
}

export default () => {
    return axios.create({
    baseURL: API_URL,
    headers: getHeaders()
})};


export const axiosUpload = axios.create({
    baseURL: API_URL,
    headers: getHeaders({noJSON:true})
});

