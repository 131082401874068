import { useEffect} from "react"
import { useNavigate } from "react-router-dom"
import { authController } from "../controllers/authController"

function Logout() {

    const navigate = useNavigate()

    useEffect(() => {
        authController.removeData()
        navigate("/", {replace: true})
    }, [])

    return (
        <section>Logging out</section>
    )
}

export default Logout;
