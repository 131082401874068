
import { differenceInDays } from 'date-fns'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import DateDisplay from "../display/DateDisplay";

export default function EditableCheckinRow({ key, formData, handleChange, handleCancel, handlePhoneClick }){

    const nuit = differenceInDays(new Date(formData.dt_to), new Date(formData.dt_from))

    return (
        <tr key={key}>
            <td> </td>
            <td> </td>
            <td data-label="Arrivée"><DateDisplay value={formData.dt_from} /> <input name='wished_checkin' type='time' placeholder='17:00' value={formData.wished_checkin} onChange={handleChange} size="5" /></td>
            <td data-label="Départ"><DateDisplay value={formData.dt_to} /> <input name='wished_checkout' type='time' placeholder='11:00' value={formData.wished_checkout} onChange={handleChange} size="5" /></td>

            <td className="not-media" data-label="Nuit">{nuit} nuit{nuit > 1 ? "s" : ""}</td>
            <td data-label="Logement"><FontAwesomeIcon icon={faHouse} color="gray"/> {formData.property.name}</td>
            <td data-label="Voyageur"><input name='traveler' type='text' placeholder='Voyageur' value={formData.traveler} onChange={handleChange} size="16" /> (<input name='travelers_count' type='text' placeholder='1' value={formData.travelers_count} onChange={handleChange} size="1"/>)</td>
            <td data-label="Contact">
                <input name='phone' type='text' placeholder='+33..' value={formData.phone} onChange={handleChange} size="16"/>
                <button type="button" onClick={handlePhoneClick} title={formData.suggested_phone}>Copie</button>
            </td>
            <td data-label="Code" colSpan="2"><input name='code' type='text' placeholder='xxx' value={formData.code} onChange={handleChange} size="10"/></td>
            <td> <input type="checkbox" name="comm_via_airbnb" id="comm_via_airbnb" onChange={handleChange} checked={formData.comm_with_airbnb} /> <label htmlFor="comm_via_airbnb">via AirBNB</label></td>
            <td><button type="submit">Sauver</button><button type="button" onClick={handleCancel}>Annuler</button></td>
            <td> </td>
        </tr>
    )
}
