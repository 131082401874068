import { format } from "date-fns";
import {useState, useEffect} from "react";

const DateDisplay = ({value}) => {

    const [dateToDisplay, setDateToDisplay] = useState(null)

    const fetchData = () => {
        const valueDate = new Date(value)
        valueDate.setHours(0,0,0,0)
        const nowDate = new Date()
        nowDate.setHours(0,0,0,0)
        const tomorrowDate = new Date(nowDate)
        tomorrowDate.setDate(tomorrowDate.getDate() + 1)
    
        if (valueDate.toDateString() == nowDate.toDateString()) {
            setDateToDisplay("Aujourd'hui")
        } else if (valueDate.toDateString() == tomorrowDate.toDateString()) {
            setDateToDisplay( "Demain")
        } else {
            let formattedDate = format(valueDate, 'EEE. dd/MM')
            formattedDate = formattedDate.replace("Mon","Lun").replace("Tue","Mar").replace("Wed","Mer").replace("Thu","Jeu").replace("Fri","Ven").replace("Sat","Sam").replace("Sun","Dim")
            setDateToDisplay(formattedDate)
        }
    }

    useEffect(() => {
        fetchData()
    }, [value])

    return <>{dateToDisplay}</>
}

export default DateDisplay