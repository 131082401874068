import { useEffect, useState, Fragment } from "react";

import { sendSms, setReservationCode, updateReservation, unlockOpener, getReservationMessage } from "../../utils/RestLocem"
import ReadOnlyCheckinRow from "./ReadOnlyCheckinRow";

import EditableCheckinRow from "./EditableCheckinRow";
import "./Checkin.sass"

import SMSPopup from "./SmsPopup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faArrowRotateRight, faSync } from "@fortawesome/free-solid-svg-icons";
import axios from "../../api/axios";
import countryCodes from "../../utils/CountryCodes.json"
import { getLink } from "../display/AppLink";

function CheckinTable({loadReservations, search=undefined}) {

    const [reservations, setReservations] = useState([])
    const [editReservationId, setEditReservationId] = useState(null)
    const [editFormData, setEditFormData] = useState({})

    const [smsPopup, setSmsPopup] = useState(false)
    const [smsFormData, setSmsFormData] = useState({})

    const [searchPattern, setSearchPattern] = useState("")

    const [timerId, setTimerId] = useState(null); 


// Fonction pour obtenir le nom du pays à partir du numéro de téléphone
function getPaysFromNumeroTelephone(numeroTelephone) {
    if (numeroTelephone == null)
    return "X"
    const country = countryCodes.find(country => numeroTelephone.startsWith(country.dial_code));
    return country ? country.code : "X";
  }

    const fetchData = async (pattern) => {
        try {
            let receivedReservations
            if (search) {
                receivedReservations = await loadReservations(pattern)
            } else {
                receivedReservations = await loadReservations()
            }
            receivedReservations = receivedReservations.map(resa => ({ ...resa,countryCode:getPaysFromNumeroTelephone(resa.suggested_phone)}))
            // console.log(receivedReservations)

            
            setReservations(receivedReservations)
        } catch (err) {
            alert(err.message)
        }
    }

    useEffect(() => {
        fetchData(searchPattern)
    }, []);

    const handleEditClick = (event, resa) => {
        event.preventDefault();
        setEditReservationId(resa.id);
        const formValues = { ...resa }

        setEditFormData(formValues)
    }

    const handleEditFormChange = (event) => {
        
        const fieldType = event.target.getAttribute("type");

        const fieldName = event.target.getAttribute("name");
        let fieldValue = null
        if (fieldType == "checkbox") {
            fieldValue = event.target.checked ? 1  : 0;
        } else {
            event.preventDefault();
            fieldValue = event.target.value;
        }
        
        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;
        setEditFormData(newFormData);

    }

    const handleSmsFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = { ...smsFormData };
        newFormData[fieldName] = fieldValue;
        setSmsFormData(newFormData);
    }

    const handleEditFormPhoneClick = (event) => {
        event.preventDefault()

        const newFormData = { ...editFormData }
        newFormData['phone'] = editFormData['suggested_phone']

        setEditFormData(newFormData);
    }    

    const handleEditFormSubmit = async (event) => {
        event.preventDefault();

        const reservationToUpdate = { 
            id:editFormData.id,
            traveler: editFormData.traveler,
            phone:editFormData.phone,
            wished_checkin:editFormData.wished_checkin,
            wished_checkout: editFormData.wished_checkout,
            travelers_count: editFormData.travelers_count,
            code: editFormData.code,
            comm_via_airbnb: editFormData.comm_via_airbnb ? 1 : 0
        }

        try{
            const result = await axios().post(`/reservation/1`, reservationToUpdate)
            const updatedReservation = result.data
            editFormData.tbchanged = updatedReservation.tbchanged
            editFormData.tbcreated = updatedReservation.tbcreated
            editFormData.tbcancelled = updatedReservation.tbcancelled
            editFormData.changes = updatedReservation.changes

            const newResas = [ ...reservations ];
            const index = reservations.findIndex((resa) => resa.id === editReservationId);
            newResas[index] = { ...editFormData };
    
            setReservations(newResas);
            setEditReservationId(null);
        } catch (error) {
            if (error.response) {
                let { status, statusText } = error.response;
                console.log(status, statusText);
            } else {
                console.log(error)
                console.log("Perte de connexion");
            }
        }



    }

    const handleSmsSendSubmit = (event) => {
        event.preventDefault();


        const loadSmsSend = async () => {

            const smsDTO = {
                number: smsFormData.phone,
                message: smsFormData.text,
                reservation: smsFormData.resa
            }
            try {
                const result = await axios().post('/sms', smsDTO)

                await updateReservation({
                    id: smsFormData.resa,
                    sms_sent: 1
                })

                const newResas = [...reservations];
                const index = reservations.findIndex((r) => r.id === smsFormData.resa);

                newResas[index].sms_sent = 1;
                setReservations(newResas)
            } catch (error) {
                if (error.response) {
                    let { status, statusText } = error.response;
                    window.alert(status, statusText);
                }
            }




            setSmsPopup(false)

        }
        loadSmsSend()

    }

    const handleCodeClick = (event, resa) => {
        event.preventDefault();
        const loadReservationCode = async (id) => {
            const updatedResa = await setReservationCode(id)

            const newResas = [...reservations];
            const index = reservations.findIndex((item) => item.id === resa.id);

            const newResa = { ...reservations[index], code: updatedResa.code }

            newResas[index] = newResa;

            setReservations(newResas);
        }
        loadReservationCode(resa.id)
    }

    const handleSMSClick = (event, resa) => {
        event.preventDefault()
        const loadSMS = async (resa) => {
            // const prop = await getProperty(resa.property)
            try {
                const dataaa = await getReservationMessage(resa.id)
                const link = getLink(resa)
                const data = { phone: resa.phone, text: dataaa.msg, resa: resa.id, link, name: resa.traveler }
                setSmsFormData(data)
                setSmsPopup(true)
            } catch (err) {
                alert(err.message)
            }
            

        }
        loadSMS(resa)
    }

    const handleUnlockOpenerClick = (event, resa) => {
        event.preventDefault();
        // console.log(resa.id)
        unlockOpener(resa.id)
    }

    const handleEditFormCancel = (event) => {
        setEditReservationId(null);
    }

    const handleReload = () => {
        fetchData()
    }

    const handleAvailableClick = async (event, reservation, value) => {
        event.preventDefault();

        if (value == 1 && reservation.prior && !reservation.prior.hc_done) {
            if (!confirm("Êtes-vous sûr ? le ménage n'est pas fait.")) {
                return
            }
        }

        const reservationToUpdate = { 
            id:reservation.id,
            available:value
        }

        try{
            const result = await axios().post(`/reservation/1`, reservationToUpdate)

            const newResas = [...reservations];
            const index = reservations.findIndex((item) => item.id === reservation.id);

            const newResa = { ...reservations[index], available: value }

            newResas[index] = newResa;

            setReservations(newResas);
            if (value == 1 && confirm("Envoie-t-on un SMS ?")) {
                handleSMSClick(event, reservation)
            }
        } catch (error) {
            if (error.response) {
                let { status, statusText } = error.response;
                console.log(status, statusText);
            } else {
                console.log(error)
                console.log("Perte de connexion");
            }
        }
    }

    const handleDeleteClick = async (event, resaa) => {
        event.preventDefault();

        const reservationToUpdate = { 
            id:resaa.id,
            tbcancelled:0, 
            tbcreated:0, 
            tbchanged:0, 
            cancelled:1,
            changes:null
        }

        try{
            const result = await axios().post(`/reservation/1`, reservationToUpdate)
            // const updatedReservation = result.data
            // editFormData.tbchanged = updatedReservation.tbchanged
            // editFormData.tbcreated = updatedReservation.tbcreated
            // editFormData.tbcancelled = updatedReservation.tbcancelled
            // editFormData.changes = updatedReservation.changes

            // const newResas = [ ...reservations ];
            const newResas = reservations.filter((resa) => resa.id != resaa.id);
    
            setReservations(newResas);
        } catch (error) {
            if (error.response) {
                let { status, statusText } = error.response;
                console.log(status, statusText);
            } else {
                console.log(error)
                console.log("Perte de connexion");
            }
        }
    }

    const handleChangeSearchPattern = (event) => {
        event.preventDefault()
        clearTimeout(timerId)
        const pattern = event.target.value
        setSearchPattern(pattern)
        const id = setTimeout(() => fetchData(pattern), 800)
        setTimerId(id)
    }


    return (
        <div id="checkin-container">
            <span onClick={handleReload}><FontAwesomeIcon icon={faArrowRotateRight} /> Recharger</span>
            {search
                ? <input type="text" name="search_pattern" id="search_pattern" value={searchPattern} placeholder="Recherche..." onChange={handleChangeSearchPattern}/>
                : ""}
            <form onSubmit={handleEditFormSubmit}>
                <table className="checkin-table">
                    <thead>
                        <tr>
                            <th colSpan={2}> </th>
                            <th>Arrivée</th>
                            <th>Départ</th>
                            <th>Nuits</th>
                            <th>Logement</th>
                            <th>Voyageurs</th>
                            <th>Contact</th>
                            <th colSpan={2}>Code</th>
                            <th>Envoi Code</th>
                            <th colSpan={2}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { reservations.map((resa, idx) => (
                            <Fragment key={`e${idx}`}>
                                {resa.id === editReservationId ? (
                                    <EditableCheckinRow
                                        
                                        formData={editFormData}
                                        handleChange={handleEditFormChange}
                                        handleCancel={handleEditFormCancel}
                                        handlePhoneClick={handleEditFormPhoneClick}
                                    />
                                ) : (
                                    <ReadOnlyCheckinRow
                                        key={`ro${idx}`}
                                        reservation={resa}
                                        handleEditClick={handleEditClick}
                                        handleCodeClick={handleCodeClick}
                                        handleSMSClick={handleSMSClick}
                                        handleUnlockOpenerClick={handleUnlockOpenerClick}
                                        handleDeleteClick={handleDeleteClick}
                                        handleAvailableClick={handleAvailableClick}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </form>
            <SMSPopup trigger={smsPopup} setTrigger={setSmsPopup} formData={smsFormData} onChange={handleSmsFormChange} onSubmit={handleSmsSendSubmit} />
        </div>
    );
}

export default CheckinTable;